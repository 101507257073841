import { Component, Input } from '@angular/core';
import { HttpErrorResponse } from 'models/http-error-response';

@Component({
  selector: 'app-http-error',
  template: `
    <div class="info_box info">
      <i class="fas fa-info-circle"></i>
      <b>{{ error?.title }}</b>
      <p>{{ error?.desc }}</p>
    </div>
  `,
})
export class HttpErrorComponent {
  @Input() error: HttpErrorResponse;
}
