<ng-container *ngIf="fieldsLoaded">
  <div
    *ngIf="
      cicoService.showNationalityStep &&
      isShowIdnowSection !== undefined &&
      cicoService.subStepForGuest == 'primary_guest'
    "
  >
    <app-pms-ci-authentication
      class="animation-out"
      [data]="data"
      [guest]="guest"
      [isIdnowActive]="isShowIdnowSection"
      (dismiss)="dismissed()"
      (done)="checkIdnow(true)"
    ></app-pms-ci-authentication>
  </div>

  <div id="reservation-form" class="animation-out">
    <ng-container
      *ngIf="
        (!cicoService.showNationalityStep && cicoService.subStepForGuest == 'primary_guest') ||
        cicoService.subStepForGuest == 'other_guest'
      "
    >
      <div class="general-grid">
        <div
          class="half-width-grid"
          *ngIf="fields.nationality && cicoService.subStepForGuest == 'other_guest'"
        >
          <straiv-select
            inputId="nationality"
            [items]="data.countriesNat | json"
            [value]="guest.nationality"
            [labelText]="fields.nationality.name"
            searchable="{{ true }}"
            searchText="{{ 'misc.search' | translate }}"
            [placeholder]="fields.nationality.name"
            (selectedEvent)="selectNationality($event)"
            [errorMessage]="'validation.nationality' | translate"
          ></straiv-select>
        </div>
        <div class="half-width-grid">
          <straiv-select
            *ngIf="fields.gender"
            ngDefaultControl
            name="gender"
            combined="false"
            [placeholder]="'booking_data.gender.select' | translate"
            [items]="genders | json"
            [helperText]="fields.gender.description"
            [(ngModel)]="guest.gender"
            (selectedEvent)="changeGenderMethod($event?.detail?.selectedValue)"
            [required]="fields.gender.required"
            [labelText]="fields.gender.name"
            [errorMessage]="'validation.required' | translate"
          ></straiv-select>
        </div>

        <div class="two-col-grid">
          <straiv-input
            *ngIf="fields.first_name"
            alphabet-only="true"
            ngDefaultControl
            id="first_name_{{ index }}"
            name="first_name_{{ index }}"
            [labelText]="fields.first_name.name"
            [placeholder]="'placeholder.input_text' | translate"
            [required]="fields.first_name.required"
            [errorMessage]="'validation.first_name' | translate"
            [(ngModel)]="guest.first_name"
            (valueChange)="setAddressName()"
          ></straiv-input>

          <straiv-input
            *ngIf="fields.last_name"
            alphabet-only="true"
            ngDefaultControl
            id="last_name_{{ index }}"
            name="last_name_{{ index }}"
            [labelText]="fields.last_name.name"
            [placeholder]="'placeholder.input_text' | translate"
            [required]="fields.last_name.required"
            [errorMessage]="'validation.last_name' | translate"
            [(ngModel)]="guest.last_name"
            (valueChange)="setAddressName()"
          ></straiv-input>
        </div>

        <div class="half-width-grid">
          <straiv-datepicker
            *ngIf="fields.date_of_birth"
            id="date_of_birth_{{ index }}"
            name="date_of_birth_{{ index }}"
            [labelText]="fields.date_of_birth.name"
            [locale]="locale"
            [localeLng]="locale"
            [(ngModel)]="guest.date_of_birth"
            #datepicker="ngModel"
            [errorMessage]="
              datepicker.touched ? (datepicker.errors | validationMessage | translate) : ''
            "
            [datevalidator]="{
              required: fields.date_of_birth.required,
              locale,
              validation: fields['date_of_birth'].validation
            }"
            [ngModelOptions]="{ updateOn: 'blur' }"
          ></straiv-datepicker>
        </div>

        <div class="group2grid" *ngIf="fields.place_of_birth">
          <straiv-input
            ngDefaultControl
            name="place_of_birth"
            [labelText]="fields.place_of_birth.name"
            [placeholder]="fields.place_of_birth.name"
            [required]="fields.place_of_birth.required"
            [errorMessage]="'validation.required' | translate"
            [(ngModel)]="guest.place_of_birth"
          ></straiv-input>
        </div>

        <div class="group3grid" *ngIf="fields.phone && phoneCountry">
          <app-phone-input
            ngDefaultControl
            name="phone"
            [isNewComponent]="true"
            [description]="'misc.phone_number' | translate"
            [countries]="data?.countries"
            [(phoneNumber)]="guest.phone"
            [required]="fields.phone.required"
            [countryFromAdr]="guest.address?.country"
          ></app-phone-input>
        </div>

        <div class="group3grid" *ngIf="fields.email">
          <straiv-input
            ngDefaultControl
            name="email"
            type="email"
            #email="ngModel"
            customEmailValidator
            controlNameToMatch="{{ hasConfirmEmail ? 'confirm_email' : undefined }}"
            [labelText]="fields.email.name"
            [placeholder]="fields.email.name"
            [required]="fields.email.required"
            [errorMessage]="email.errors | validationMessage | translate"
            [(ngModel)]="guest.email"
          ></straiv-input>

          <straiv-input
            *ngIf="hasConfirmEmail"
            ngDefaultControl
            #confirmEmail="ngModel"
            name="confirm_email"
            type="email"
            required="true"
            auto-select="true"
            allow-paste="false"
            customEmailValidator
            controlNameToMatch="{{ hasConfirmEmail ? 'email' : undefined }}"
            [labelText]="'misc.confirm_email_address' | translate"
            [placeholder]="'misc.confirm_email_address' | translate"
            [errorMessage]="confirmEmail.errors | validationMessage | translate"
            [confirmEmail]="email.value"
            [(ngModel)]="data.confirmEmail"
          ></straiv-input>
        </div>

        <straiv-input
          *ngIf="fields.profession"
          ngDefaultControl
          name="profession"
          [labelText]="fields.profession.name"
          [placeholder]="fields.profession.name"
          [required]="fields.profession.required"
          [errorMessage]="'validation.required' | translate"
          [(ngModel)]="data.profession"
        ></straiv-input>
      </div>
    </ng-container>

    <div
      class="guest-address"
      *ngIf="
        cicoService.subStepForGuest === 'private_address' ||
        cicoService.subStepForGuest === 'other_guest'
      "
    >
      <ng-container *ngIf="fields.private_address">
        <div class="apply-address" *ngIf="!primaryGuest">
          <straiv-button
            data-cy="apply-address"
            name="apply-address-button"
            [leftLabel]="'address.apply' | translate"
            left-icon="fas fa-circle-arrow-down"
            variant="flat"
            (buttonClick)="applyAddress()"
          ></straiv-button>
        </div>

        <pms-address
          ngDefaultControl
          [guestAddress]="guest.address"
          [fieldType]="'private_address'"
          [field]="fields.private_address"
          [data]="data"
        ></pms-address>

        <ng-container *ngIf="primaryGuest && deviatingBillingAdr">
          <div class="billing-address">
            <straiv-checkbox
              ngDefaultControl
              name="use_billing_address"
              [labelText]="fields['invoice_address'].name"
              [helperText]="fields['invoice_address']?.description"
              [(ngModel)]="cicoService.deviatingBillingAddress"
              (valueChange)="onChangeBillingAdCallback($event)"
            ></straiv-checkbox>
          </div>
          <ng-container *ngIf="cicoService.deviatingBillingAddress">
            <straiv-section-header
              [heading]="'address.billing_address' | translate"
              [description]="'address.business_trip_company_name_mandatory' | translate"
            ></straiv-section-header>
            <app-pms-invoice-address-form
              class="animation-out"
              [data]="data"
              [deviatingBillingAdr]="deviatingBillingAdr"
              [newDesign]="true"
              [staticAddress]="false"
              [withoutFolio]="true"
            ></app-pms-invoice-address-form>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="this.primaryGuest">
        <div class="segment-button segment-button-sl" *ngIf="travelPurposeField">
          <straiv-label
            [header]="travelPurposeField?.name"
            [description]="travelPurposeField?.description"
          ></straiv-label>
          <straiv-segment-button
            ngDefaultControl
            id="travel-toggle"
            name="travel-toggle"
            [leftText]="'booking_data.travel_purpose.private_trip' | translate"
            [rightText]="'booking_data.travel_purpose.business_trip' | translate"
            [value]="data.incident.reservation.travel_purpose"
            left-value="private_trip"
            right-value="business_trip"
            [disabled]="travelPurposeLock"
            (buttonClick)="reasonForTravelToggle($event)"
          ></straiv-segment-button>
        </div>

        <ng-container *ngIf="fields.car_licence || transportation">
          <div class="parking-flexbox">
            <straiv-label
              [header]="'booking_data.transportation.title' | translate"
              [description]="'booking_data.transportation.sub_title' | translate"
            ></straiv-label>
            <div class="car_licence">
              <straiv-radio-group
                name="my_radio_group"
                [options]="[
                  { label: 'booking_data.transportation.noPrivate' | translate, value: 'public' },
                  { label: 'booking_data.transportation.yesPrivate' | translate, value: 'private' }
                ]"
                [value]="data.incident.reservation.car_licence ? 'private' : 'public'"
                (valueChange)="setTransportationType($event.detail)"
              ></straiv-radio-group>

              <straiv-input
                ngDefaultControl
                name="car_licence"
                [labelText]="fields.car_licence.name"
                [placeholder]="fields.car_licence.name"
                [required]="fields.car_licence.required"
                [errorMessage]="'validation.required' | translate"
                [disabled]="carLicenceDisabled"
                [(ngModel)]="data.incident.reservation.car_licence"
                (inputBlur)="disableCarLicence($event)"
              ></straiv-input>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="
      cicoService.subStepForGuest === 'identification_guest' ||
      cicoService.subStepForGuest === 'other_guest'
    "
  >
    <app-passport-form
      [guest]="guest"
      [guestIndex]="guestIndex"
      [locale]="locale"
    ></app-passport-form>
  </div>
</ng-container>

<app-loader *ngIf="!fieldsLoaded"></app-loader>
