import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { ModalService } from 'common/modal/modal.service';
import { Business } from 'models/business';
import { DomModal } from 'models/dom_modal';
import { Guest } from 'models/guest';
import { Module } from 'models/module';
import { FeedbackService } from 'modules/feedback/feedback.service';
import { SmartFeedbackModalComponent } from 'modules/feedback/smart/modal/smart_feedback_modal.component';
import { PushCategoriesComponent } from 'modules/push/categories/categories.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import NoSleep from 'nosleep.js';
import { fromEvent, interval, Observable, of, Subscription } from 'rxjs';
import { delay, filter, skipWhile, take, takeWhile } from 'rxjs/operators';
import { A2hsService } from 'services/a2hs.service';
import { BusinessService } from 'services/business.service';
import { ChatbotService } from 'services/chatbot.service';
import { CookiesService } from 'services/cookies.service';
import { GuestService } from 'services/guest.service';
import { PushNotificationService } from 'services/push_notification.service';
import { StorageService } from 'services/storage.service';
import { StyleService } from 'services/style.service';
import { JourneyService } from 'services/websocket/journey.service';
import { TeaserService } from 'services/websocket/teaser.service';
import { WebsocketService } from 'services/websocket/websocket.service';
import { StraivService } from 'straiv/straiv.service';
import { MediaQueryService } from 'services/mediaQuery.service';
import { environment } from 'environments/environment';
import { LanguageService } from '../language/language.service';

@Component({
  selector: 'app-init',
  templateUrl: './init.component.html'
})
export class InitComponent implements OnInit, OnDestroy {
  resizeObservable: Observable<Event>;
  classList = document.querySelector('html')['className'];
  supportServiceworker = this.classList.indexOf('can_serviceworker') !== -1;
  supportTouch = this.classList.indexOf('can_touchevents') !== -1;
  business: Business;
  guest: Guest;
  push_module: Module;
  push_value: Number;
  color = '';
  journey = 'default';
  wizard = false;
  kiosk = false;
  subResult = undefined;
  self = this;
  locale: string;
  sidebar = false;
  footer = false;
  sidebarItems = true;
  height = 0;
  width = 0;
  sizes = [];
  mobileSizes = ['super-tiny', 'tiny', 'mobile', 'phablet', 'tablet-up'];
  desktopSizes = ['tablet', 'desktop', 'tv'];
  fieldChanges: any[] = [];
  @ViewChild('search', {read: ViewContainerRef, static: true}) search: ViewContainerRef;
  private subscriptions: Subscription = new Subscription();
  private taskModule: boolean;

  constructor(
    public globals: Globals,
    private route: ActivatedRoute,
    private a2hsService: A2hsService,
    private router: Router,
    private translate: TranslateService,
    private styleService: StyleService,
    private feedbackService: FeedbackService,
    private guestService: GuestService,
    private businessService: BusinessService,
    private modalService: ModalService,
    private pushNotificationService: PushNotificationService,
    private deviceService: DeviceDetectorService,
    private straivService: StraivService,
    private api: ApiService,
    private storageService: StorageService,
    private teaserService: TeaserService,
    private cookiesService: CookiesService,
    private afMessaging: AngularFireMessaging,
    private wsService: WebsocketService,
    private journeyService: JourneyService,
    private chatbot: ChatbotService,
    private languageService: LanguageService,
    private mediaQueryService: MediaQueryService
  ) {}

  private static uselessChange(attribute) {
    return attribute.startsWith('aria') || attribute.startsWith('ng-reflect');
  }

  private static toggleRequired(target) {
    const parent = target.closest('.form-box');
    if (parent) {
      const label = parent.querySelector('label');
      const fieldset = parent.querySelector('fieldset');

      if (label) {
        label.classList.remove('valid', 'required', 'disabled');
        if (target.hasAttribute('required') || fieldset) {
          if (target.hasAttribute('disabled') || fieldset?.hasAttribute('disabled')) {
            label.classList.add('disabled');
          } else if (target.classList.contains('ng-valid')) {
            label.classList.add('valid');
          } else {
            label.classList.add('required');
          }
        }
      }
    }
  }

  ngOnInit() {
    this.observeResize();

    // auth interceptor will redirect if there is no token
    this.api.get('api').subscribe(
      () => {
        this.checkNodes();
        this.subscriptions.add(
          this.businessService.current_business
            .pipe(filter(Boolean), take(1))
            .subscribe((business: Business) => {
              this.business = business;
              this.subscriptions.add(
                this.guestService.currentGuest
                  .pipe(filter(Boolean), take(1))
                  .subscribe((guest: Guest) => {
                    this.guest = guest;

                    this.testMode();

                    this.guest.swipe = this.supportTouch;
                    this.guest.save();

                    this.wizard = this.guest.place.wizard;
                    this.guestService.cookieSubj.next(this.guestService.getCookies());

                    this.languageService.setLanguage(this.guest.locale).then(() => {
                      if (!this.wizard) {
                        this.loadMaps();
                        this.wsService.init();
                        this.chatbot.init();

                        this.detectWidthChange();

                        if (this.guest?.popups()) {
                          this.cookiesService.open();
                          if (this.guest?.smart_feedback) {
                            this.smartFeedback();
                          }
                          if (this.guest.can_subscribe) {
                            this.pushSubscription();
                          }
                        }

                        this.tvRemoteControlInit();
                      } else {
                        this.noSleep();
                      }
                      this.subscribeGuestDependent();
                    });
                  })
              );
            })
        );

        this.router.routerState.root.queryParams.subscribe((params) => {
          if (!!params) {
            if (!!params['mId']) {
              this.api.put('pwa/push_subscription/' + params['mId'], {}).subscribe(
                () => {},
                (error) => {
                  console.log(error);
                }
              );
            }
            if (!!params['utm_source']) {
              if (params['utm_source'] === 'pwa' && !!params['cryptcode']) {
                const device = this.deviceService.getDeviceInfo();
                this.straivService
                  .getToken(params['cryptcode'], device, '', [])
                  .pipe(filter(Boolean))
                  .subscribe(
                    (success) => {
                      this.guest = this.straivService.createGuest(success);
                      this.router.navigate(['g', this.guest.code], {replaceUrl: true});
                    },
                    () => {}
                  );
              } else if (params['utm_source'] === 'notification' && !!params['path']) {
                this.router.navigateByUrl('g/' + this.business.code + '/' + params['path']);
              }
            }
            if (!!params['pending_reservation']) {
              this.subscriptions.add(
                of(true)
                  .pipe(delay(3000))
                  .subscribe(() => {
                    const body = new DomModal();
                    body.title = 'pending_reservation.title';
                    body.description = 'pending_reservation.description';
                    body.redirect = false;
                    this.modalService.open(body, true);
                  })
              );
            }
          }
        });
        this.subscriptions.add(
          this.globals.taskObservable.subscribe((taskModule) => {
            if (this.taskModule !== taskModule) {
              setTimeout(() => {
                const container = document.getElementById('container');
                this.footer = !taskModule;
                this.taskModule = taskModule;
                this.taskModule
                  ? container.classList.add('task')
                  : container.classList.remove('task');
              });
            }
          })
        );
      },
      () => {}
    );
  }

  loadMaps() {
    this.styleService.scriptToHead(
      `https://maps.googleapis.com/maps/api/js?key=${environment.googleMaps}`,
      true
    );
  }

  subscribeGuestDependent() {
    this.subscriptions.add(
      this.guestService.currentGuest.pipe(filter(Boolean)).subscribe((guest: Guest) => {
        this.guest = guest;
        this.checkWizard();

        this.subscriptions.add(
          this.businessService.current_business
            .pipe(filter(Boolean))
            .subscribe((business: Business) => {
              this.business = business;
              this.color = this.business.style.background;
              this.styleService.set_styles(this.business, this.guest);

              this.api.get('logo').subscribe(
                (data: any) => {
                  if (this.business.logo !== data.logo) {
                    this.business.logo = data.logo;
                    this.business.logo_upright = data.logo_upright;
                    this.business.save();
                  }
                },
                () => {}
              );

              this.observeStandalone();

              this.subscriptions.add(
                this.businessService.currentLocale
                  .pipe(filter(Boolean))
                  .subscribe((locale: string) => {
                    if (this.locale !== locale) {
                      this.api.get('teaser').subscribe((teaser: any) => {
                        this.teaserService.setTeaser(teaser);
                      });
                      this.locale = locale;
                    }
                  })
              );

              if (!this.wizard) {
                this.a2hsService.subscribeModal(this.business);
                this.a2hsService.manage(guest, business);

                this.subscriptions.add(
                  this.journeyService.journey.pipe(filter(Boolean)).subscribe((journey: string) => {
                    if (this.journey !== journey) {
                      this.api.get('teaser').subscribe(
                        (teaser: any) => {
                          this.teaserService.setTeaser(teaser);
                        },
                        () => {}
                      );
                    }
                    this.journey = journey;
                  })
                );
              }
            })
        );
      })
    );
  }

  observeStandalone() {
    this.subscriptions.add(
      this.mediaQueryService.standalone.subscribe(() => {
        this.a2hsService.manage(this.guest, this.business);
        this.checkWizard();
      })
    );
  }

  checkWizard() {
    this.wizard = this.guest.place.wizard;
    if (this.wizard) {
      this.chatbot.destroy();
    }
  }

  testMode() {
    this.route.queryParams.subscribe((query) => {
      if (query['test_mode']) {
        this.guest.test_mode = query['test_mode'];
        this.guest.save();
      }
    });
  }

  pushSubscription() {
    if (this.supportServiceworker) {
      const self = this;
      navigator.serviceWorker.ready
        .then(() => {
          this.afMessaging.getToken.subscribe((token) => {
            if (token) {
              self.subscriptions.add(
                this.pushNotificationService.getSubscription(token).subscribe(
                  (success) => {
                    if (success) {
                      self.subResult = success;
                      self.subscriptions.add(
                        this.guestService.currentGuest.pipe(filter(Boolean)).subscribe(() => {
                          self.globals.guest.subscription_id = self.subResult['subscription'].id;
                          self.globals.guest.push_categories =
                            self.subResult['subscription'].categories;
                          self.storageService.setItem('current_guest', self.globals.guest);
                          self.showNotificationModal();
                        })
                      );
                    } else {
                      self.showNotificationModal();
                    }
                  },
                  () => {}
                )
              );
            } else {
              self.showNotificationModal();
            }
          });
        })
        .catch(() => {});
    }
  }

  smartFeedback() {
    this.globals.popupSubj.next(false);
    this.feedbackService.initSubscriptions().then(
      () => {
        this.subscriptions.add(
          this.feedbackService.open_modal.pipe(filter(Boolean)).subscribe(() => {
            this.subscriptions.add(
              interval(3000)
                .pipe(
                  skipWhile((value, index) => this.globals.isPmsModule() || this.taskModule),
                  take(1)
                )
                .subscribe(() => {
                  const body = new DomModal();
                  body.redirect = false;
                  body.component = SmartFeedbackModalComponent;
                  this.modalService.open(body);
                })
            );
          })
        );
      },
      () => {}
    );
  }

  sidebarSetting() {
    interval(15)
      .pipe(
        takeWhile(() => this.business !== undefined),
        take(1)
      )
      .subscribe(() => {
        const mobile = this.sizes.some((size) => this.mobileSizes.includes(size));
        const desktop = this.sizes.some((size) => this.desktopSizes.includes(size));
        this.sidebar =
          (this.business.mobile_sidebar && mobile) || (this.business.desktop_sidebar && desktop);

        if (this.sidebar && this.sidebarItems) {
          document.body.classList.remove('no-sidebar');
        } else {
          document.body.classList.add('no-sidebar');
        }
      });
  }

  tvRemoteControlInit() {
    window.addEventListener('message', (event) => {
      if (event.data.type === 'keypress') {
        const evt = <any>new Event('keypress');
        evt.keyCode = event.data.code;
        document.body.dispatchEvent(evt);
      }
    });
  }

  noSleep() {
    try {
      const noSleep = new NoSleep();
      document.addEventListener(
        'click',
        function enableNoSleep() {
          document.removeEventListener('click', enableNoSleep, false);
          noSleep.enable();
        },
        false
      );
    } catch (_e) {}
  }

  // <editor-fold desc="Layout Functions>
  observeResize() {
    this.resizeObservable = fromEvent(window, 'resize');
    this.subscriptions.add(
      this.resizeObservable.subscribe(() => {
        this.detectHeightChange();
        this.detectWidthChange();
        if (this.globals.isSafari()) {
          this.iframeHeight();
        }
      })
    );
  }

  detectHeightChange() {
    setTimeout(() => {
      const navigation = document.getElementById('navigation');
      if (navigation) {
        const newHeight = this.maxHeight();
        if (newHeight > -1 && this.height !== newHeight) {
          navigation.style.minHeight = newHeight + 'px';
          this.height = newHeight;
        }
      }
    });
  }

  maxHeight() {
    let height = document.getElementById('content')?.clientHeight;
    if (!height) {
      return -1;
    }

    const body = document.body,
      html = document.documentElement;
    const doc = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    height = Math.min(...[height, doc]);
    return Math.max(...[height, body.offsetHeight]);
  }

  detectWidthChange() {
    setTimeout(() => {
      if (this.width !== document.body.clientWidth) {
        this.width = document.body.clientWidth;
        const sizes = [];
        if (this.width <= 370) {
          sizes.push('tiny');
        }
        if (this.width <= 600) {
          sizes.push('mobile');
        }
        if (this.width > 600 && this.width <= 890) {
          sizes.push('phablet');
        }
        if (this.width > 890 && this.width <= 1060) {
          sizes.push('tablet-up');
        }
        if (this.width > 890 && this.width <= 1320) {
          sizes.push('tablet');
        }
        if (this.width > 1320 && this.width <= 1600) {
          sizes.push('desktop');
        }
        if (this.width > 1600) {
          sizes.push('tv');
        }
        this.sizes = sizes;
        this.sidebarSetting();
      }
    });
  }

  iframeHeight() {
    const container = document.getElementById('container');
    const iframe = document.getElementsByClassName('iframe_container')[0];
    if (iframe) {
      const content = document.getElementById('content');
      const cs = getComputedStyle(content);
      let padding = parseFloat(cs.paddingTop);
      if (!container.classList.contains('cico')) {
        padding += parseFloat(cs.paddingBottom);
      }
      document.getElementsByTagName('app-link')[0]?.classList?.add('iOS');
      (<any>iframe).style.height = window.innerHeight - padding + 'px';
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private showNotificationModal(): void {
    if (!this.subResult && this.business.push) {
      this.push_value = this.business.push.trigger_value * 1000;
      of(true)
        .pipe(delay(Number(this.push_value)))
        .subscribe(() => {
          const body = new DomModal();
          body.title = this.globals.translate('push.title');
          body.description = this.globals.translate('push.description');
          body.redirect = false;
          body.notificationState = this.pushNotificationService.notificationState;
          body.component = PushCategoriesComponent;

          this.modalService.open(body);
        });
    }
  }

  private checkNodes() {
    const EXCLUDE = ['DIV', 'SPAN', 'LABEL'];
    const self = this;

    const changes = new MutationObserver((mutations) => {
      this.detectHeightChange();
      mutations?.forEach((mutation: any) => {
        if (mutation.target && [...mutation.addedNodes].length) {
          this.checkFrame();
          if (this.sidebar && mutation.target.className === 'scroll') {
            this.showSidebar(mutation.target);
          } else if (!EXCLUDE.includes(mutation.target.nodeName)) {
            this.observeInputs(self);
          }
        } else if (mutation.target && [...mutation.removedNodes].length) {
          this.checkFrame();
          if (this.sidebar && mutation.target.className === 'scroll') {
            this.showSidebar(mutation.target);
          } else if (!EXCLUDE.includes(mutation.target.nodeName)) {
            this.fieldChanges.forEach((change) => {
              change.observer.disconnect();
            });
            while (this.fieldChanges.length) {
              this.fieldChanges.pop();
            }
            this.observeInputs(self);
          }
        }
      });
    });

    changes.observe(document.body, {
      childList: true,
      subtree: true
    });
  }

  private checkFrame() {
    const container = document.getElementById('container');
    const frame = container?.getElementsByClassName('iframe_container')?.length;
    if (frame) {
      document.getElementById('container')?.classList?.add('frame');
      if (this.globals.isSafari()) {
        this.iframeHeight();
      }
    } else {
      document.getElementById('container')?.classList?.remove('frame');
    }
  }

  private showSidebar(target) {
    this.sidebarItems = [...target.children].filter((c) => c.style.display !== 'none').length > 0;
    this.sidebarSetting();
  }

  // </editor-fold

  private observeInputs(self) {
    document
      .querySelectorAll(
        'input:not([type="file"]), textarea, select, ng-select, app-datepicker, signature-pad, international-phone-number'
      )
      .forEach((field) => {
        const parent = field.closest('.form-box');
        const label = parent?.querySelector('label');
        const change = this.fieldChanges.filter((obs) => obs.field === field).length;
        const phone =
          field.closest('international-phone-number') &&
          field.tagName !== 'INTERNATIONAL-PHONE-NUMBER';
        if (!parent || !label || change || phone || field.closest('.ng-select-container')) {
          return;
        }
        InitComponent.toggleRequired(field);

        if (
          parent.classList.contains('form-box') ||
          parent.parentElement.parentElement.classList.contains('form-box')
        ) {
          label.setAttribute('data-after', self.translate.instant('required.text'));
        }

        const fieldChanges = new MutationObserver((mutations) => {
          this.detectHeightChange();
          mutations.forEach((mutation: any) => {
            if (!InitComponent.uselessChange(mutation.attributeName)) {
              InitComponent.toggleRequired(mutation.target);
            }
          });
        });
        fieldChanges.observe(field, {
          attributes: true
        });
        this.fieldChanges.push({field: field, observer: fieldChanges});
      });
  }
}
