import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CusInfo } from 'models/pms/pms_reservation';
import { filter } from 'rxjs/operators';
import { FormFieldKeys, UserActionType, Step, TravelPurpose, CusProductTypes } from 'pms_enums';
import { GenericCheckIn } from 'pms_models/generic_check_in';
import { GenericData } from 'pms_models/generic_data';
import { Globals } from 'base';
import { PmsBaseDirective } from 'pms_base/base.directive';
import { PmsCiCoService } from 'cico_service';
import { PmsGuest } from 'models/pms/pms_guest';
import { fadeInAnimation } from 'app/route-animations';
import { Module } from 'models/module';
import { Subscription } from 'rxjs';
import { StepperService } from 'services/pms/stepper.service';
import { FieldValue } from 'models/field_value';
import { Field } from 'models/field';
import { TravelInfoSubSteps } from 'models/pms/stepper';
import { EventAggregatorService } from 'services/events/event-aggregator.service';
import { EventConstants } from 'global_enums';
import { ReservationCard } from 'models/reservation_card';

@Component({
  selector: 'app-pms-ci-travel-info',
  templateUrl: './travel_info.component.html',
  styleUrls: ['./travel_info.component.scss'],
  animations: [fadeInAnimation],
})
export class PmsTravelInfoComponent
  extends PmsBaseDirective
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() submited: boolean = false;
  @Input() locale: string = 'default';

  protected readonly TravelPurpose = TravelPurpose;

  numbersFellows = [];
  transportation: boolean;
  toggleCityTax: boolean;
  hasFellows = null;
  fellow_types = [];
  fields = {} as any;
  fieldsLoaded: boolean;
  minDepartureDate: string;
  maxFellows: number = 10;
  mappedFormFieldsValues: { [key: string]: FieldValue } = {};
  travelPurposeLock: boolean = false;

  totalGuestCount: number;
  nightsCount: number;
  reservationCusInfo: CusInfo;

  subscriptions: Subscription = new Subscription();
  module: Module;

  rightSide = false;
  rule: any;

  loaded: boolean = false;
  reservationCardData: ReservationCard;

  substeps = TravelInfoSubSteps;
  public cusProductTypes = CusProductTypes;
  public currentSubstepName: string;
  public isConfirmationDialogOpen: boolean = false;
  public bookingError: boolean = false;

  constructor(
    public globals: Globals,
    public cicoService: PmsCiCoService,
    protected stepperService: StepperService,
    private _cdr: ChangeDetectorRef,
    private eventService: EventAggregatorService,
  ) {
    super(cicoService, Step.reservation, undefined, stepperService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.cicoService.setShowFooter(true);
    this.currentSubstepName = this.cicoService.getCurrentStepName();

    this.subscriptions.add(
      this.eventService.getEvent(EventConstants.openBreakfastBookingConfirmation).subscribe(() => {
        this.isConfirmationDialogOpen = true;
      }),
    );

    this.subscriptions.add(
      this.eventService.getEvent(EventConstants.navigationChange).subscribe(() => {
        this.currentSubstepName = this.cicoService.getCurrentStepName();
      }),
    );

    this.subscriptions.add(
      this.cicoService.navigation.subscribe((type) => {
        if (type === UserActionType.next) {
          this.globals.posthogSetCapture({ active: true });
        }
      }),
    );

    this.loadCus(this.cicoService.cusLoadedSubj.value);
    if (!this.loaded) {
      this.subscriptions.add(
        this.cicoService.cusLoaded.subscribe((loaded: boolean) => {
          this.loadCus(loaded);
        }),
      );
    }

    // When a form is submitted, set the 'submitted' flag to true. this is for showing the validation satate of the input.
    this.subscriptions.add(
      this.eventService.getEvent(EventConstants.afterSubmitForm).subscribe(() => {
        this.globals.triggerBlurEvents(this.form);
      }),
    );

    this.numbersFellows = this.countSelection(10);

    this.subscriptions.add(
      this.cicoService.fieldsUpdated.pipe(filter(Boolean)).subscribe(() => {
        this.setFields();
        this._cdr.detectChanges();
      }),
    );
  }

  loadCus(loaded) {
    const hasOffers = this.cicoService.cusProducts.breakfast || this.cicoService.cusProducts.room;
    this.loaded = loaded;
    this.cicoService.preventNext = !loaded;
    if (this.loaded && hasOffers) {
      this.globals.posthogStartRecord();
    }
  }

  ngAfterViewInit() {
    this.subscriptions.add(
      this.cicoService.cusLoaded.pipe(filter(Boolean)).subscribe(() => {
        setTimeout(() => {
          const autoSkipUntilStep = this.cicoService.autoSkipUntilStep;
          if (autoSkipUntilStep && autoSkipUntilStep !== Step.reservation) {
            this.stepperService.nextStep();

            // Upselling is available, navigate to the next step.
            // If external payment is successful or failed, system should redirect to invoice page if cus is there.
            if (
              this.cicoService.cusProducts.breakfast &&
              this.cicoService.activeStep.key === Step.reservation &&
              this.cicoService.activeStep.totalSteps > 1
            ) {
              this.stepperService.nextStep();
            }

            // Cusotm feilds are available, navigate to the next step.
            if (this.data.module.usableFields(false)) {
              this.stepperService.nextStep();
            }

            this.cicoService.setAutoSkipUntilStep(undefined);
          } else if (autoSkipUntilStep === Step.reservation) {
            this.cicoService.setAutoSkipUntilStep(undefined);
          }
        });
      }),
    );
  }

  protected fetchData() {
    this.subscriptions.add(
      this.cicoService.data.pipe(filter(Boolean)).subscribe((data: GenericData) => {
        this.data = data;
        this.reservationCardData = this.cicoService.setReservationCardInfo(data);
        this.setFields();
        this.invoiceAddressPosition();
        this.totalGuestCount = this.data.incident.reservation.guestsCount();
        this.nightsCount = this.data.incident.reservation.nightsCount();
        this.reservationCusInfo = this.data.incident.reservation.cusInfos();
        this.fellows = data.blank;
        this.fellow_types = this.data.module.settings.fellows_kinds;
        this.transportation = ['ch'].includes(this.data.business.address.country);
        this.toggleCityTax = this.data.module.settings.city_tax_toggle;
        this.travelPurposeLock =
          this.data.module.settings.travel_purpose_lock &&
          (data.incident.reservation.travel_purpose?.length || 0) > 0;

        this.hasFellows =
          this.data.incident.reservation.adults + this.data.incident.reservation.children > 0;

        if (this.data.blank) {
          this.loaded = true;
          this.setMinDeparture();
          this.cicoService.hideBackButton(true);
          this.cicoService.hideNextButton(false);
        }

        if (!this.transportation && this.field_for('car_licence')) {
          (<GenericCheckIn>this.data.incident).setTransportation();
        }

        if (this.ownInvoiceAddress) {
          this.cicoService.setAddressName();
        } else {
          const navSub = this.cicoService.navigation.subscribe((type) => {
            if (type === UserActionType.next) {
              this.cicoService.setAddressName();
              navSub?.unsubscribe();
            }
          });
        }
      }),
    );
  }

  setFields() {
    [
      ['reservation_infos', 'booking'],
      ['reservation_infos', 'arrival'],
      ['reservation_infos', 'departure'],
      ['reservation_infos', 'unit'],
      ['reservation_infos', 'total_price'],
      ['reservation_infos', 'time_of_arrival'],
      ['reservation_infos', 'time_of_departure'],
      ['coming_from', null],
      ['destination', null],
      ['border_crossing', null],
      ['border_crossing_date', null],
      ['car_licence', null],
      ['fellows', null],
      ['marketing', null],
      ['travel_purpose', null],
    ].forEach((entry) => {
      let key = entry[1] ? entry[1] : entry[0];
      this.fields[key] = this.field_for(entry[0], entry[1]);
      this.prepareFormTemplateFields(key, this.fields[key]);
    });
    this.fieldsLoaded = true;
  }

  /**
   * @param templateFieldKey key that will be used to be the key of the object of mapping between fields and form.fields_values
   * @param field field to be mapped
   * @var mappedFormFieldsValues is the object of the mapping between fields and form.fields_values
   */
  prepareFormTemplateFields(templateFieldKey: string, field: Field): void {
    if (!field) {
      return;
    }
    this.mappedFormFieldsValues[templateFieldKey] = this.modelFor(field.id);
  }

  checkFields() {
    this.cicoService.updatedFields();
  }

  checkCityTax() {
    if (this.toggleCityTax) {
      const remove = this.data.incident.reservation.travel_purpose === TravelPurpose.business_trip;
      this.subscriptions.add(
        this.cicoService.pmsService.setCityTax(remove).subscribe(() => {
          this.cicoService.folioUpdateSubj.next({ cus: false });
        }),
      );
    }
  }

  fellowsCheck(value) {
    if (!value) {
      this.removeFellows();
    }
  }

  fellowsQty(kind: 'children' | 'adults', selectedValue: any) {
    if (!this.hasFellows) {
      this.removeFellows();
      return;
    }
    this.data.incident.reservation[kind] = selectedValue.value;
    const quantity = Math.min(selectedValue.value, this.maxFellows);
    if (kind === 'adults') {
      this.changeFellows(
        quantity,
        this.data.incident.reservation.related_guests,
        FormFieldKeys.adultFellows,
      );
    } else {
      this.changeFellows(
        quantity,
        this.data.incident.reservation.children_guests,
        FormFieldKeys.childrenFellows,
      );
    }
  }

  private changeFellows(qty: number, list: any[], formField: string): void {
    if (qty == null) return;

    // Find the current user step related to guest processing
    const userStep = this.cicoService.userSteps.find((step) => step.key === Step.guests);
    if (!userStep) return;

    // Adjust the list length to match the required quantity
    const difference = qty - list.length;
    if (difference > 0) {
      // Add missing guests
      list.push(...Array.from({ length: difference }, () => new PmsGuest({ group: formField })));
    } else if (difference < 0) {
      // Remove excess guests
      list.splice(difference);
    }

    userStep.totalSteps =
      this.data.incident.reservation['adults'] +
      (this.data.incident.reservation['children'] > 0 ? 1 : 0) +
      1;
  }

  removeFellows() {
    this.data.incident.reservation.adults = 0;
    this.data.incident.reservation.children = 0;
    this.changeFellows(
      0,
      this.data.incident.reservation.related_guests,
      FormFieldKeys.adultFellows,
    );
    this.changeFellows(
      0,
      this.data.incident.reservation.children_guests,
      FormFieldKeys.childrenFellows,
    );
  }

  setMinDeparture(): void {
    const arrivalDate = this.data.incident.reservation?.arrival;
    this.minDepartureDate = this.globals.today(1, arrivalDate);
  }

  countSelection(max: number) {
    const arr: { name: string; value: number }[] = [];
    for (let i = 0; i <= max; i++) {
      arr.push({ name: i.toString(), value: i });
    }
    return arr;
  }

  modelFor(id) {
    return this.data.incident?.field_values?.find((field) => field.id === id);
  }

  reasonForTravelToggle(event: any) {
    this.data.incident.reservation.travel_purpose =
      event?.detail === TravelPurpose.private_trip
        ? TravelPurpose.private_trip
        : TravelPurpose.business_trip;
    this.checkCityTax();
    this.checkFields();
  }

  fellowToggle(event: any) {
    this.hasFellows = event?.detail == 'false' ? false : true;
    this.fellowsCheck(this.hasFellows);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
