<input
  #fileInput
  type="file"
  class="hidden"
  [name]="name"
  [id]="inputId"
  [accept]="accept"
  (change)="onFileChange($event)"
  [required]="required"
  [attr.data-cy]="name"
  [(ngModel)]="guest.passport_img_path"
  required="required"
  #model="ngModel"
/>
<straiv-error-label
*ngIf="required && !fileInput.value && model.touched"
[errorMessage]=" 'service.check_in.documents.picture_description' | translate"
></straiv-error-label>
