import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PmsCiCoService } from 'cico_service';
import { of, Subscription } from 'rxjs';
import { filter, map, mergeMap, reduce, take, tap, toArray } from 'rxjs/operators';
import { GenericData } from 'pms_models/generic_data';
import { PmsReservation } from 'pms_models/pms_reservation';
import { OverlayAction } from 'pms_enums';
import { Business } from 'models/business';
import { BusinessService } from 'services/business.service';
import { ApiService } from 'api_service';
import { PinCode, ExternalDoor, Door } from 'models/pin_door';
import { HttpErrorResponse } from 'models/http-error-response';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-pms-ci-pin-codes',
  templateUrl: './pin_codes.component.html',
  styleUrls: ['./pin_codes.component.scss'],
})
export class PmsPinCodesComponent implements OnInit, OnDestroy {
  @Input() reservationUUID!: string;

  private subscriptions: Subscription = new Subscription();
  codeTitle: string;
  door: any;
  hasComfortCode: boolean = false;
  waiting = true;
  sag: boolean;
  pinCodes: Record<string, PinCode>;
  loadingDoors = false;

  @Input() id: any;
  @Input() terminal: boolean;
  @Input() hideFinishButton = false;
  error: HttpErrorResponse;

  constructor(
    private ciCoService: PmsCiCoService,
    private businessService: BusinessService,
    private api: ApiService,
  ) {}

  ngOnInit(): void {
    this.businessService.current_business
      .pipe(filter(Boolean), take(1))
      .subscribe((business: Business) => {
        this.sag = business.tech.door_system.type === 'sag';
        this.codeTitle = `pms_door.pin_codes.${this.sag ? 'start_code' : 'title'}`;

        if (!this.id && this.terminal) {
          this.ciCoService.disableButtons(true);
          this.subscriptions.add(
            this.ciCoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
              this.fetchDoorsCodes(data.incident.reservation.uuid);
            }),
          );
        } else {
          this.fetchDoorsCodes(this.reservationUUID);
        }
      });
  }

  get recordLength(): number {
    return Object.keys(this.pinCodes).length;
  }

  /**
   * get the doors with codes in case of pin codes
   */
  private fetchDoorsCodes(reservationUUID: string) {
    this.loadingDoors = true;
    const params = reservationUUID ? new HttpParams().set('uuid', reservationUUID) : undefined;
    this.subscriptions.add(
      this.api
        .get('door/list/pin_codes', { params })
        .pipe(
          mergeMap((response: any) => response?.reservation?.doors || []),
          mergeMap((doors: any) => doors?.entries || []),
          tap((entry: ExternalDoor) => this.checkComfortCode(entry)),
          map((door: ExternalDoor) => this.doorAdapter(door)),
          reduce(this.groupDoorsByCode.bind(this), {}),
        )
        .subscribe(
          (res: Record<string, PinCode>) => {
            this.loadingDoors = false;
            this.pinCodes = res;
          },
          (error) => {
            this.loadingDoors = false;
            this.pinCodes = {};
            this.error = error.error as HttpErrorResponse;
          },
        ),
    );
  }

  /**
   * Call back function for reduce that is responsible of grouping doors by activation codes.
   * @param acc accumulator for records depending on the activation code.
   * @param currentDoor current door we are processing, it is the down stream value during processing the data inside the stream.
   * @returns Record with doors which have the same activation code.
   */
  private groupDoorsByCode(
    acc: Record<string, PinCode>,
    currentDoor: Door,
  ): Record<string, PinCode> {
    if (!acc[currentDoor.start_code]) {
      acc[currentDoor.start_code] = {
        comfort_code: null,
        doorsData: [],
      };
    }
    // Append doors to the corresponding activation code with comfort code if it exists.
    acc[currentDoor.start_code] = {
      comfort_code: currentDoor.comfort_code,
      doorsData: acc[currentDoor.start_code].doorsData.concat(currentDoor),
    };
    return acc;
  }

  /**
   * checking if comfort code exists
   * @param entry is the external door resieved by api
   */
  private checkComfortCode(entry: ExternalDoor): void {
    if (!this.hasComfortCode && entry.comfort_code) {
      this.hasComfortCode = true;
    }
  }

  /**
   * doorAdapter is being used to get the required door from the data received from BE assuring type-safety.
   * @param door it is the door with the required properties to be shown in the pin codes table .
   * @param startCodes it is used to handle the repetitions of the same starting code for different code => no need to duplicate the same starting code.
   * @returns mapping between the received door (type Externdal) and the actual data type we want (type Door) using Adapter Pattern.
   */
  private doorAdapter(door: ExternalDoor): Door {
    return {
      name: door.name,
      start_code: door.start_code,
      comfort_code: door.comfort_code,
    };
  }

  close(): void {
    this.ciCoService.closeOverlay(OverlayAction.cancel);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
