import { NgModule } from '@angular/core';

import { HttpErrorComponent } from './http-error.component';

@NgModule({
  imports: [],
  exports: [HttpErrorComponent],
  declarations: [HttpErrorComponent],
  providers: [],
})
export class HttpErrorModule {}
