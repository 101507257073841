<form #form="ngForm" class="redesign">
  <fieldset class="pin-preview-container">
    <ng-container *ngIf="!loadingDoors; else loading">
      <table class="doors-table" *ngIf="recordLength > 0">
        <thead>
          <tr>
            <th [class.has-border]="!hasComfortCode" translate>
              pms_door.pin_codes.activation_code
            </th>
            <th *ngIf="hasComfortCode" class="has-border" translate>
              pms_door.pin_codes.access_code
            </th>
            <th translate>pms_door.pin_codes.door</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let pin of pinCodes | keyvalue">
            <td class="pin-code">
              <span>{{ pin?.key || '' }}</span>
            </td>
            <td class="pin-code" *ngIf="hasComfortCode">
              <span>{{ pin.value?.comfort_code || '' }}</span>
            </td>
            <td class="pin-code">
              <div class="flex-cell">
                <span *ngFor="let door of pin.value?.doorsData || []">{{ door?.name || '' }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <app-http-error class="error-wrapper" *ngIf="!!error" [error]="error"></app-http-error>

    <div class="button-grid" *ngIf="terminal && !hideFinishButton">
      <button class="btn-filled" (click)="close()" translate>misc.finish</button>
    </div>
  </fieldset>
</form>

<ng-template #loading>
  <app-loader [small]="true"></app-loader>
</ng-template>
