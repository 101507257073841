import { PmsReservation } from 'models/pms/pms_reservation';
import { BehaviorSubject } from 'rxjs';
import { Address } from '../address';

export class PmsFolio {
  number: any;
  internal_id: string;
  window: string;
  invoice: any;
  existing_payments: boolean;
  balance: number;
  payable_balance: number;
  balance_with_currency: any;
  pre_auth_balance: number;
  pre_auth_balance_with_currency: any;
  sum: number;
  currency: string;
  check: string;
  create_invoice: boolean;
  payment: string;
  viewable: boolean;
  url: string;
  sca_valid: boolean;
  filter: any;
  mod_type: string;
  static_address: string;
  address_lock: boolean;
  window_lock: boolean;
  address: Address;
  vat: Array<PmsFolioVat> = new Array<PmsFolioVat>();
  positions: Array<PmsFolioPosition> = new Array<PmsFolioPosition>();
  reservation: PmsReservation;

  constructor(raw_json?: any) {
    this.number = raw_json?.number;
    this.internal_id = raw_json?.internal_id;
    this.window = raw_json?.window;
    this.invoice = raw_json?.invoice;
    this.existing_payments = raw_json?.existing_payments;
    this.balance = raw_json?.balance;
    this.payable_balance = raw_json?.payable_balance;
    this.balance_with_currency = raw_json?.balance_with_currency;
    this.pre_auth_balance = raw_json?.pre_auth_balance;
    this.pre_auth_balance_with_currency = raw_json?.pre_auth_balance_with_currency;
    this.sum = raw_json?.sum;
    this.currency = raw_json?.currency;
    this.check = raw_json?.check;
    this.create_invoice = raw_json?.create_invoice;
    this.payment = raw_json?.payment;
    this.viewable = raw_json?.viewable;
    this.url = raw_json?.url;
    this.sca_valid = raw_json?.sca_valid;
    this.filter = raw_json?.filter;
    this.mod_type = raw_json?.mod_type;
    this.static_address = raw_json?.static_address;
    this.window_lock = raw_json?.window_lock;
    this.address_lock = raw_json?.address_lock;
    this.address = new Address(raw_json?.address);
    this.reservation = new PmsReservation(raw_json?.reservation);
    for (const vat of raw_json?.vat || []) {
      this.vat.push(new PmsFolioVat(vat));
    }
    for (const position of raw_json?.positions || []) {
      this.positions.push(new PmsFolioPosition(position));
    }
  }

  valid(): boolean {
    return this.viewable && this.positions.filter((pos) => pos.viewable).length > 0;
  }

  paid(checkBalance = false): boolean {
    return this.check === 'paid' && (!checkBalance || this.balance <= 0);
  }

  unpaid(): boolean {
    return this.check === 'unpaid' && this.payable_balance > 0;
  }

  authorized(): boolean {
    return this.check === 'authorized';
  }

  paidOrAuthorized(): boolean {
    return ['paid', 'authorized'].includes(this.check);
  }

  paymentAllowed(): boolean {
    return this.payment !== 'no_payment' && !this.paid();
  }

  paymentOptional(): boolean {
    return this.payment === 'optional' && !this.paid();
  }

  paymentRequired(): boolean {
    return this.payment === 'required' && !this.paid();
  }

  gross(): number {
    return this.positions.reduce((a, b) => a + b.gross, 0);
  }

  net(): number {
    return this.positions.reduce((a, b) => a + b.net, 0);
  }
}

export class PmsFolioPosition {
  number: any;
  name: string;
  date: string;
  gross: number;
  net: number;
  vat: number;
  percent: number;
  payable: boolean;
  viewable: boolean;

  constructor(raw_json?: any) {
    this.number = raw_json?.number;
    this.name = raw_json?.name;
    this.date = raw_json?.date;
    this.gross = raw_json?.gross;
    this.net = raw_json?.net;
    this.vat = raw_json?.vat;
    this.percent = raw_json?.percent;
    this.payable = raw_json?.payable;
    this.viewable = raw_json?.viewable;
  }

  ident(index): string {
    return this.number || 'check-' + index;
  }
}

export class PmsFolioVat {
  percent: number;
  sum: number;
  pseudo: boolean;

  constructor(raw_json?: any) {
    this.percent = raw_json?.percent;
    this.sum = raw_json?.sum;
    this.pseudo = raw_json?.pseudo;
  }
}

export class PmsFolioInfo {
  loaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  longLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  cusLongLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  noFolios: boolean;
  error: boolean;
  state: string;
}
