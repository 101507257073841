import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '../../../../common/loader/loader.module';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { PmsDoorPinCodesRoutingModule } from './pin_codes-routing.module';
import { PmsDoorPinCodesComponent } from './pin_codes.component';
import { HttpErrorModule } from 'shared/http-error/http-error.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    PmsDoorPinCodesRoutingModule,
    LoaderModule,
    FilterPipeModule,
    HttpErrorModule,
],
  exports: [PmsDoorPinCodesComponent],
  declarations: [PmsDoorPinCodesComponent],
})
export class PmsDoorPinCodesModule {}
